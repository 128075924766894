.card-info {
    position: relative;
    height: 190px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.5);
    }
    .content {
        @include center_pos;
        display: flex;
        align-items: center;
        width: calc(100% - 48*2px);
        z-index: 9;
        .img {
            width: 91px;
            height: 91px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .text {
            padding-left: 20px;
            line-height: 1;
            width: calc(100% - 91px - 20px);
            color: var(--rc-white);
            .title {
                margin: 0 0 9px 0;
                font-size: 16px;
                font-weight: 900;
                text-transform: uppercase;
                color: var(--rc-white);
                white-space: pre;
                letter-spacing: 0.09em;
            }
            .desc {
                margin: 0;
                font-family: "Century";
                span {
                    &:first-child {
                        letter-spacing: -0.5px;
                        display: inline-block;
                        margin-right: 4px;
                        font-family: 'Century' !important;
                        font-size: 24px;
                        font-weight: 700;
                    }
                    &:last-child {
                        font-weight: 900;
                        font-size: 14px;
                        font-family: 'Noto Sans JP', sans-serif;
                    }
                }
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 50px);
        border: 1px solid var(--rc-white);
        opacity: 0.6;
        z-index: 1;
    }

    .ant-skeleton {
        .ant-skeleton-header {
            vertical-align: middle;
            .ant-skeleton-avatar {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.45) 37%, rgba(255, 255, 255, 0.1) 63%);
                background-size: 400% 100%;
                width: 91px;
                height: 91px;
            }
        }
        .ant-skeleton-content {
            .ant-skeleton-title {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.45) 37%, rgba(255, 255, 255, 0.1) 63%);
                background-size: 400% 100%;
            }
            .ant-skeleton-paragraph {
                li {
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.45) 37%, rgba(255, 255, 255, 0.1) 63%);
                    background-size: 400% 100%;
                }
            }
        }
    }
}

.card-intro {
    position: relative;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.5);
    }
    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 52px);
        display: flex;
        height: calc(100% - 37px);
        justify-content: space-between;
        flex-direction: column;
        padding: 5px 26px;
        .img {
            width: 91px;
            height: 91px;
            img {
                width: 100%;
            }
        }
        .text {
            .title {
                color: var(--rc-white);
                font-size: 18px;
                font-weight: 700;
                margin: 0;
                text-transform: uppercase;
                .ant-divider {
                    border-inline-start: 1px solid var(--rc-white);
                }
                .vertical-divider {
                    font-size: 18px;
                }
            }
            .desc {
                font-size: 13px;
                letter-spacing: 0.09em;
                margin: 5px 0 0 0;
                color: var(--rc-header);
                font-weight: 500;
            }
            .title-time {
                line-height: 1;
                .text-before{
                    font-size: 6px;
                    span {
                        font-size: 12px;
                        &:nth-child(2) {
                            margin-right: 3px;
                            font-weight: 500;
                            font-size: 9px;
                            font-family: "Noto Sans JP", sans-serif !important;
                        }
                    }
                }
                p {
                    font-family: 'Century' !important;
                    font-weight: 500;
                    margin: 0;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: var(--rc-white);
                    white-space: pre;
                    letter-spacing: -0.5px;
                }

                span {
                    letter-spacing: -0.5px;
                    margin: 0;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: var(--rc-white);
                    white-space: pre;
                    text-align: center;
                    &:nth-child(2) {
                        font-family: 'Century' !important;
                        font-weight: 500;
                        font-size: 11px;
                    }
                }
            }
            .number {
                .group-number-user {
                    display: flex;
                    align-items: flex-end;
                }
                color: var(--rc-white);
                font-weight: 700;
                margin-bottom: 10px;
                div {
                    font-size: 11px;
                }
                .span-number {
                    margin-bottom: -1px;
                    font-size: 15px;
                    font-weight: 700;
                    display: inline-block;
                    &:first-child {
                        margin-left: 2px;
                    }
                }

                .span-line {
                    font-size: 13px;
                    display: inline-block;
                    margin: 0 5px;
                }

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 3px;
                    margin-bottom: -1.5px;
                }
            }
        }
        .btn-custom {
            text-align: center;
            .ant-btn {
                background-color: var(--rc-white);
                font-size: 16px;
                color: var(--rc-gray);
                font-family: 'Noto Sans JP', sans-serif;
                padding-inline-start: 33px;
                padding-inline-end: 33px;
                font-weight: 500;
                height: 50px;

                &.first-shop {
                    width: 100%;
                }
            }
        }
        .btn-heart {
            position: absolute;
            top: 10px;
            right: 15px;
            .anticon {
                cursor: pointer;
                color: var(--rc-white);
                font-size: 27px;
            }
            &.active {
                .anticon {
                    color: var(--rc-pink);
                }
            }
        }
    }

    &.first-shop {
        height: calc(100vh - 150px);
    }
    
    .btn-custom {
        .ant-btn {
            &:hover{
                border: none;
            }
        }   
    }
}

.show-counter {
    display: inline-flex;
    .countdown {
        display: flex;
        p {
            font-family: 'Century' !important;
            margin: 0;
        }
    }
    p {
        margin: 0;
    }
}

.pc-version {
    text-align: center;
    height: 100vh;
    min-height: 414px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        width: 550px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            padding: 20px;
            margin: 0;
        }
        .logo {
            img {
                width: 91px;
                height: 32px;
                margin: 0 auto 12px;
            }
        }
        .qr-code {
            padding: 30px 0 60px;
            img {
                width: 150px;
                height: 150px;
            }
        }
        h1 {
            font-size: 18px;
            font-weight: 500;
        }
        p {
            font-size: 12px;
        }
    }
}

.mobile-version {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .content {
        padding: 0px 25px;
        img {
            width: 100%;
        }
    }
    .btn-group {
        margin-top: -50px;
        a {
            border-radius: 4px;
            padding: 10px 30px;
            background: var(--rc-green);
            color: var(--rc-white);
            text-decoration: none;
        }
    }
}

.error {
    font-size: 12px;
    margin: 0;
    color: var(--rc-pink);
}

.full-width {
    width: 100% !important;
}

.hidden {
    display: none;
}
